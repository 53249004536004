import React from 'react';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import css from './ListingPage.module.css';
import { isArrayLength } from '../../util/dataExtractors';

function SectionTags(props) {
  const { publicData, history } = props;
  const tags = isArrayLength(publicData?.tags) && publicData.tags;
  return tags ? (
    <div className={css.tags}>
      {tags?.map((item, i) => (
        <span
          key={i}
          className={css.tag}
          onClick={() => {
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                { keywords: item && item.trim() }
              )
            );
          }}
        >
          #{item && item.trim()}
        </span>
      ))}
    </div>
  ) : null;
}

export default SectionTags;
